import { Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

/* IMAGE IMPORTS */
import Logo from "../../assets/amberwood-re-logo.webp";

const HeaderFullWidth = () => {
  return (
    <header className="header">
      <div className="container-max-width">
        <div className="header-section">
          <Link to="/">
            <img src={Logo} className="logo" alt="Amberwood Real Estate" />
          </Link>

          {/* HEADER NAVIGATION  */}
          <Navbar expand="xl" className="header-navbar">
            <Navbar.Toggle aria-controls="menu-expand" />
            <Navbar.Offcanvas id="menu-expand" placement="start">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-expand-lg">
                  <Link to="/">
                    <div className="logo">
                      <img
                        src={Logo}
                        className="logo"
                        alt="Amberwood Real Estate"
                      />
                    </div>
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="main-menu">
                  <Nav.Link href="/" className="menu-link">
                    Home
                  </Nav.Link>
                  <Nav.Link href="/our-agents" className="menu-link">
                    Our Agents
                  </Nav.Link>
                  <Nav.Link href="/our-office" className="menu-link">
                    Our Office
                  </Nav.Link>
                  <NavDropdown title="For Buyers" className="dropdown">
                    <NavDropdown.Item href="/mortgage-calculator">
                      Mortgage Calculator
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/buyer-overview">
                      Buyer Overview
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/find-a-home">
                      Find a Home
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/featured-listings">
                      Featured Listings
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="For Sellers" className="dropdown">
                    <NavDropdown.Item href="/seller-overview">
                      Seller Overview
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/home-evaluation">
                      Home Evaluation
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Agent Resources" className="dropdown">
                    <NavDropdown.Item href="/become-an-agent">
                      Become an Agent
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/real-estate-practice-course">
                      Real Estate Practice Course
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/real-estate-principles-course">
                      Real Estate Principles Course
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/real-estate-finance-course">
                      Real Estate Finance Course
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/two-day-crash-course">
                      Two-Day Crash Course
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/blogs" className="menu-link">
                    Blogs
                  </Nav.Link>
                  <Nav.Link href="/events" className="menu-link">
                    Events
                  </Nav.Link>
                  <Nav.Link href="/about-us" className="menu-link">
                    About Us
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default HeaderFullWidth;
